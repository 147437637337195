.timelineItem {
  @apply relative flex flex-col justify-center items-center;
}

.timelineItem:not(:first-child)::before {
  @apply content-[''] bg-neutral-light absolute w-full h-[3px] right-2/4 -mt-6;
}

.selector {
  @apply flex z-10 relative items-center justify-center rounded-full transition-all duration-200;
}

.selector:not(:disabled) {
  @apply bg-new-blue cursor-pointer w-6 h-6;
}

.selector:disabled {
  @apply border-new-orange border-[7px] w-10 h-10 bg-white cursor-default;
}