    body {
        font-family: Ebrima, Helvetica, sans-serif;
    }

    .corpo {
        padding-left: 10%;
        padding-right: 10%;
        justify-content: space-between;
        text-align: justify;
    }

    .corpo h2 {
        font-size: 32px;
        color: #2A2B61;
    }

    #tabelafim {
        border-collapse: collapse;
        width: 100%;
    }

    #tabelafim td, #tabelafim th {
        border: 1px solid #ddd;
        padding: 8px;
    }

    #tabelafim tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    #tabelafim thead {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #2b2f6b;
        color: white;
    }

    .corpo p {
        margin-bottom: 15px;
    }
    .corpo a{
        color: blue;
    }

    .P-circle {
        list-style-type: circle; 
        margin-left: 25px;
    }

    .P-count {
        list-style-type: decimal; 
        margin-left: 25px;
    }

    .P-square {
        list-style-type: square; 
        margin-left: 25px;
    }

    
